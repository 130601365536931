<template>
  <div class="container">
    <ReservationIsCreated
      :reservation="{ id: $route.params.id }"
    ></ReservationIsCreated>
    <div class="u-text-align-center u-margin-top">
      <a target="_blank" href="https://rezzervasyon.com">
        <img src="@/assets/images/Logo.png" alt="" />
      </a>
      <p>
        <small
          ><i>{{
            $t("track.footer", { version: new Date().getFullYear() })
          }}</i></small
        >
      </p>
      <div class="u-margin-top">
        <a target="_blank" href="https://rezzervasyon.com">
          <img src="@/assets/images/google-play.webp" alt="" />
        </a>
        <a
          target="_blank"
          class="u-margin-left-xsmall-sm-up u-margin-top-md-down u-display-inline-block"
          href="https://rezzervasyon.com"
        >
          <img src="@/assets/images/app-store.webp" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationIsCreated from "@/components/Chat/ReservationIsCreated.vue";
export default {
  components: {
    ReservationIsCreated,
  },
};
</script>

<style></style>
